import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    Button
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/images/Scholar-Box-Logo.png';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { COLOR_LINKTEXT, COLOR_ORANGE_PRIMARY, COLOR_DARK, COLOR_WHITE, COLOR_TEXT } from '../../constants/color';
import SwipeableTemporaryDrawer from './headerDrawer';
import HeaderPopover from './headerPopover';
import { handlePrefetchPage } from '../../utils/prefetchUtils';
import ComponentContainer from '../../Layout/Wrapper/componentContainer';
import ComponentOutermostContainer from '../../Layout/Wrapper/componentOutermostWrapper';


const CustomButton1 = styled(Button)((theme) => ({
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '6px',
    color: COLOR_WHITE,
    fontFamily: 'Poppins',
    fontSize: '16px',
    border: `1.5px solid ${COLOR_ORANGE_PRIMARY} !important`,
    backgroundColor: COLOR_ORANGE_PRIMARY,
    padding: '6px 12px',
    lineHeight: '125%',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: `${COLOR_ORANGE_PRIMARY} !important`,
    },
}));

const CustomButton2 = styled(Button)((theme) => ({
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '6px',
    color: COLOR_ORANGE_PRIMARY,
    fontFamily: 'Poppins',
    fontSize: '16px',
    border: `2px solid ${COLOR_ORANGE_PRIMARY} !important`,
    backgroundColor: COLOR_WHITE,
    padding: '6px 12px',
    lineHeight: '125%',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    fontWeight: 500,
    '&:hover': {
        backgroundColor: `${COLOR_WHITE} !important`,
    },
}));

const CustomLink = styled(Link)(() => ({

    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.8px',
    lineHeight: '150%',
    fontStyle: 'normal',
}));


const Header = () => {
    const currentPath = window.location.pathname;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const loginDataFromRedux = useSelector((state) => state.login);
    const { error, isAuth, isLoading, loginDetails, signinResponse, token } = loginDataFromRedux;
    const loggedInUserData = useSelector((state: any) => state.login.loginDetails.data);
    const userRole = loggedInUserData?.user?.usersRoles?.[0]?.role?.title;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [userRoleRedirectPath, setUserRoleRedirectPath] = useState('');
    const anchorRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleResize = () => {
            setAnchorEl(null);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {
    //     if (userRole === 'Student') {
    //         setUserRoleRedirectPath('dashboard/student/');
    //     } else if (userRole === 'Admin') {
    //         setUserRoleRedirectPath('dashboard/admin/');
    //     } else if (userRole === 'Counselor') {
    //         setUserRoleRedirectPath('dashboard/counselor/');
    //     } else {
    //         setUserRoleRedirectPath('dashboard/student');
    //     }
    // }, [userRole]);
    return (
        <AppBar component="nav" position="sticky"
        sx={{
            width: 'full',
            display: 'flex',
            backgroundColor: `${COLOR_WHITE} !important` ,
            boxShadow: 'none',
            alignItems: 'center',
            borderBottom: `1px solid #d1d1d1`,
            zIndex: 999,
            
        }}>
                <ComponentContainer
                styleProps={{ backgroundColor: COLOR_WHITE, zIndex: 9999}}>
                <Toolbar
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: { xs: '10px 10px', md: 'auto' }
                        }}>
                        <Link to="/"
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src={LogoImage}
                                alt="Scholar Box Logo"
                                style={{
                                    height: window.innerWidth > 768 ? '55px' : '50px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Link>

                        {windowWidth > 740 ? (
                            <Box sx={{
                                width: 'fit-content',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '28px',
                                alignItems: 'center'
                            }}>
                                {!isAuth && (
                                    <Box sx={{ width: 'fit-content' }}>
                                        <CustomLink to="/aboutCounselor" onMouseEnter={() => handlePrefetchPage('schoolCounselorPage')}
                                            rel="noopener noreferrer"
                                            sx={{
                                                color: currentPath === '/aboutCounselor' ? COLOR_ORANGE_PRIMARY : COLOR_TEXT,
                                                textDecoration: currentPath === '/aboutCounselor' ? 'underline' : 'none',
                                                textUnderlineOffset: '4px',
                                                '&:hover': {
                                                    color: COLOR_ORANGE_PRIMARY
                                                }
                                            }}
                                        >
                                            School Counselor
                                        </CustomLink>
                                    </Box>
                                )}

                                <Box>
                                    {isAuth ? (
                                        <>
                                            {currentPath !== '/signUp/yourEducationDetails' && currentPath !== '/signUp/moreAboutYou' && (
                                                <CustomLink to={`${userRoleRedirectPath}`}
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        color: COLOR_TEXT,
                                                        textDecoration: 'none',
                                                        textUnderlineOffset: '4px',
                                                        '&:hover': {
                                                            color: COLOR_ORANGE_PRIMARY
                                                        }
                                                    }}
                                                >
                                                    Dashboard
                                                </CustomLink>
                                            )}
                                        </>

                                    ) :
                                        (
                                            <CustomLink to="/users/login" onMouseEnter={() => handlePrefetchPage('loginPage')}
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: currentPath === '/users/login' ? COLOR_ORANGE_PRIMARY : COLOR_TEXT,
                                                    textDecoration: currentPath === '/users/login' ? 'underline' : 'none',
                                                    textUnderlineOffset: '4px',
                                                    '&:hover': {
                                                        color: COLOR_ORANGE_PRIMARY
                                                    }
                                                }}
                                            >
                                                Log In
                                            </CustomLink>

                                        )}
                                </Box>
                                {!isAuth && (
                                    <Box>
                                        <CustomLink to='/signup' onMouseEnter={() => handlePrefetchPage('signUpPage')}
                                            sx={{
                                                color: currentPath === '/signup' ? COLOR_ORANGE_PRIMARY : COLOR_TEXT,
                                                textDecoration: currentPath === '/signup' ? 'underline' : 'none',
                                                textUnderlineOffset: '4px',
                                                '&:hover': {
                                                    color: COLOR_ORANGE_PRIMARY
                                                }
                                            }}
                                        >
                                            Sign Up
                                        </CustomLink>
                                    </Box>
                                )}
                                <SwipeableTemporaryDrawer />
                            </Box>
                        ) : (
                            <Box>
                                <IconButton
                                    onClick={handleClick}
                                >
                                    <MenuIcon
                                    />
                                </IconButton>
                                <Drawer className="header-drawer" anchor="right" open={open} onClose={handleClose}>
                                    <HeaderPopover onClose={handleClose} />
                                </Drawer>
                            </Box>
                        )}

                    </Box>
                </Toolbar>
            </ComponentContainer>
        </AppBar>
    )
}

export default Header