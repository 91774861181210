import React, { useEffect, useState, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../Components/Dashboard/common/Toast/index.tsx';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';



// import Home from './../pages/Home';

const Privacypolicy = lazyWithPreload(()=> import('../pages/Privacypolicy/index'));


const Termofuse = lazyWithPreload(()=> import('../pages/Termofusepage'));

// import LoginPage from '../pages/Login';
import { Routes, Route, Navigate } from 'react-router-dom';
const Register = lazyWithPreload(()=> import('../pages/SignUpAboutYou/index.tsx'));
// import Register from '../pages/SignUpAboutYou/index.tsx';
const VerifyEmail = lazyWithPreload(()=> import('../pages/SignUpVerifyEmail/index.tsx'));
const StudentEducation = lazyWithPreload(()=> import('../pages/SignUpEducationalDetails/index.tsx'));
const MoreAboutStudents = lazyWithPreload(()=> import('../pages/SignUpPersonalDetails/index.tsx'));
const ContactUs = lazyWithPreload(()=> import('../pages/ContactUs/index.tsx'));


import SavedScholarshipsTable from '../pages/StudentViewedPage/SavedTable/index.tsx'
import AwardedScholarshipsTable from '../pages/StudentViewedPage/AwardedTab/index.tsx'
import Header1 from '../Layout/Header/header.tsx';
import Header from '../Layout/Header/index.tsx';
import Footer1 from '../Layout/Footer/footer.tsx';
import Footer from '../Layout/Footer/index.tsx';
import LowerFooter from '../Layout/LowerFooter/index.tsx';
import CopyRight from '../Layout/CopyRight/index.tsx';
import Dashboard from '../pages/Dashboard/index'
import DashboardHomePage from '../pages/Dashboard/Home/index.tsx'
import Scholarships from '../pages/Dashboard/Scholarships/index.tsx';
import LearningCenter from '../pages/Dashboard/LearningCenter/index.tsx';
import LearningCenterMoreInfo from '../pages/Dashboard/LearningCenter/ArticleDetail.tsx';


const LearningCenterInfo = lazyWithPreload(()=> import('../Components/LearningCenter/LearningCenterInfo.tsx'));
const LearningCenterOut = lazyWithPreload(()=> import('../pages/Dashboard/LearningCenter/Learning.tsx'));

import Faq from '../pages/Dashboard/Faq/index.tsx';
import Settings from '../pages/Dashboard/Settings/index.tsx';
import Matched from "../pages/Matched/TopNavigation/TopNavigation"
import { useLocation } from 'react-router-dom';
import StudentMyAccount from '../pages/Dashboard/MyAccountPage/index.tsx'
import UnAuthorizedPage from '../pages/UnAuthorizedPage';
import { useSelector } from 'react-redux';
const FAQ = lazyWithPreload(()=> import('../pages/FAQ/index.tsx'));
// import FAQ from '../pages/FAQ/index.tsx';
import ChangePasswordComponent from '../pages/Dashboard/MyAccount/ChangePassword/index.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecoverPasswordComponent from '../pages/RecoverPassword/index.tsx';
import OTP_Page from '../pages/RecoverPassword/OTP_Page.tsx';
import NewPasswordPage from '../pages/RecoverPassword/NewPasswordPage.tsx';
import ActivityPage from '../pages/Dashboard/Activity/index.tsx';
import CounselorStudentPage from '../pages/CounselorDashboard/Students/index.tsx';
import CounselorApplicationManagerPage from '../pages/CounselorDashboard/ApplicationManager/index.tsx';
import ListManagerPage from '../pages/CounselorDashboard/ListManger/index.tsx';
import ApplyScholarshipPage from '../components/ApplyProcess';

const Counselor = lazyWithPreload(()=> import('../pages/Counselor/Counselor/index.tsx'));
const Home = lazyWithPreload(()=> import('./../pages/Home'));

/* Admin Component imports*/
import AdminDash from '../pages/AdminDashboard/index.tsx';
import AdminScholarship from '../pages/AdminDashboard/AdminScholarships/index.tsx';
import AdminUsers from '../pages/AdminDashboard/Users/index.tsx';
import AdminOrganization from '../pages/AdminDashboard/Organizations/index.tsx'
import HighSchools from '../pages/AdminDashboard/Organizations/HighSchools/index.tsx';
import OrgTab from '../pages/AdminDashboard/Organizations/Tab.tsx';
import ProvideTable from '../pages/AdminDashboard/Organizations/Providers/index.tsx';
import NonprofitTable from '../pages/AdminDashboard/Organizations/NonProfits/index.tsx';
import Students from '../pages/AdminDashboard/Students/index.tsx';
import AdminSponsorPage from '../pages/AdminDashboard/Sponsors/index.tsx';
import ActiveSponsorsPage from '../Components/Admin/ActiveSponsors/index.tsx';
import EditSponsorForm from '../Components/Admin/EditSponsor/index.tsx';
import EditExternalScholarshipPage from '../Components/Admin/External/EditExternalScholarships/index.tsx';
import AdminEditStudentInfoPage from '../pages/AdminDashboard/Students/EditStudentInfo/index.tsx';
import AdminSettingsPage from '../pages/AdminDashboard/Settings/index.tsx';


import AddScholarship from '../Components/AddScholarship/index.tsx';
import CounselorViewScholarship from '../pages/CounselorDashboard/ApplicationManager/ViewScholarship/index.tsx'
import CounselorApplyScholarship from '../pages/CounselorDashboard/ApplicationManager/ApplyScholarship/index.tsx'
import NewListManager from '../pages/CounselorDashboard/ListManger/AddScholarshipFromListManagerPage/index.tsx'
import NewListManagerAdd from '../pages/CounselorDashboard/ListManger/AddScholarship/index.tsx'
import Table from '../pages/CounselorDashboard/ListManger/Multiple/index.tsx'
import SecurityPage from '../pages/SecurityPage/index.tsx'
import PaperScholarship from '../pages/CounselorDashboard/ListManger/PaperScholarship/index.tsx';
import EmbeddedScholarhips from '../pages/CounselorDashboard/ListManger/ScholarshipPage/Embed/index.tsx'
import ListOfUrl from "../pages/CounselorDashboard/ListManger/List_Of_Url/index.tsx";
import CounserlorAccount from '../pages/CounselorDashboard/MyAccount/index.tsx';
import AdminAccount from '../pages/AdminDashboard/AdminAccount/Admin.tsx';
import NumberOfApplicants from '../pages/CounselorDashboard/ApplicationManager/NoOfApplicantsPage/index.tsx';
import OrganizationDetailTabNavigation from '../pages/CounselorDashboard/OrganizationDetail/index.tsx'
import ViewPublicScholarships from '../pages/CounselorDashboard/ListManger/ScholarshipPage/Embed/PublicScholarshipView/index.tsx'
/* Sign up  page*/
const SignUpOptionsPage = lazyWithPreload(()=> import('../pages/SignUp/index.tsx'));

const CounselorRegister = lazyWithPreload(()=> import('../pages/SignUp/CounselorSignUp/CounselorDetails/index.tsx'));

import Resource from '../pages/CounselorDashboard/Resources/index.tsx'
const AboutUs = lazyWithPreload(()=> import('../pages/AboutUs/index.tsx'));

import ScholarBoxBranding from "../pages/CounselorDashboard/OrganizationDetail/index.tsx"

const Scholarship101 = lazyWithPreload(()=> import('../pages/Scholarship101/index.tsx'));
import EditExternalScholarshipDetailsPage from '../Components/Admin/External/EditExternalScholarshipComponent/EditScholarshipDetailsComponent/index.tsx';
import {GOOGLE_ANALYTICS_TRACKING_ID} from '../services/api/config.ts';
import TagManager from 'react-gtm-module';
import { replace } from 'formik';
const LazyLogin = lazyWithPreload(()=> import ('../pages/Login'))

//preload  with lazy 
import { lazyWithPreload }  from '../services/prefetch/prefetch.ts';

export const handlePrefetchPage = (pageName: string) => {
    switch (pageName) {
        case 'loginPage':
            LazyLogin.preload();
            break;
        case 'signUpPage':
            Register.preload();
            break;
        case 'schoolCounselorPage':
            Counselor.preload();
            break;
        case 'faqPage':
            FAQ.preload();
            break;
        case 'aboutUsPage':
            AboutUs.preload();
            break;
        case 'learningCenterOutPage':
            LearningCenterOut.preload();
            break;
        case 'contactUsPage':
            ContactUs.preload();
            break;
        default:
            break;
    }
};
