import { getGenderId, getEthnicities, getOrganizationCollegesURL, getOrganizationSchoolsURL, getYearsInSchoolURL, getStatesURL, getCategoriesURL} from './endpoints';
import axios from 'axios';

export const fetchGenderDataAPI = async ( ) => {
    const GENDER_DATA_URL = getGenderId();
    let res = await axios({
        method: 'GET',
        url: `${GENDER_DATA_URL}`
    });
    return res.data;
};

export const  fetchEthnicitiesDataAPI = async () => {
    const ETHNICITY_DATA_API = getEthnicities(); 
    let res = await axios({
        method: 'GET',
        url: `${ETHNICITY_DATA_API}`
    });
    return res.data;
};

export const fetchStatesDataAPI = async () => {
    const isGetAll=true;
    const STATES_DATA_API = getStatesURL();
    let res = await axios({
        method: 'GET',
        url: `${STATES_DATA_API}?isGetAll=${isGetAll}`
    });
    return res.data;
};

export const fetchYearsInSchoolDataAPI = async () => {
    const YEARS_IN_SCHOOL_API = getYearsInSchoolURL();
    let res = await axios({
        method: 'GET',
        url: `${YEARS_IN_SCHOOL_API}`
    });
    return  res.data;
};

export const fetchSchoolDataAPI = async () => {
    const SCHOOL_API = getOrganizationSchoolsURL();
    let res = await axios({
        method: 'GET',
        url: `${SCHOOL_API}`
    });
    return  res.data;
};

export const fetchCollegeDataAPI = async () => {
    const COLLEGE_API = getOrganizationCollegesURL();
    let res = await axios({
        method: 'GET',
        url: `${COLLEGE_API}`
    });
    return  res.data;
};

export const fetchCategoriesListAPI = async () => {
    const CATEGORIES_API = getCategoriesURL();
    let res = await axios({
        method: 'GET',
        url: `${CATEGORIES_API}`
    });
    return res.data;
}