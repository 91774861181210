import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetchGenderDataAPI, fetchEthnicitiesDataAPI, fetchStatesDataAPI, fetchYearsInSchoolDataAPI, fetchSchoolDataAPI, fetchCollegeDataAPI, fetchCategoriesListAPI } from '../../services/api/staticApis';


const initialState = {
    error: "",
    genderData: null,
    ethnicitiesData: null,
    schoolsList: null,
    collegesList: null,
    categoriesList: null,
    yearsInSchoolIdData: null,
    statesData: null
}

export const fetchGenderData = createAsyncThunk(
    'fetchgenderdata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchGenderDataAPI();
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchEthnicityData = createAsyncThunk(
    'fetchethnicitydata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchEthnicitiesDataAPI();
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchStatesData = createAsyncThunk(
    'fetchstatesdata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchStatesDataAPI();
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchSchoolListData = createAsyncThunk(
    'fetchschoollistdata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchSchoolDataAPI();
            return res;
        }
        catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchCollegeListData = createAsyncThunk(
    'fetchcollegelistdata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchCollegeDataAPI();
            return res;
        }
        catch (err) {
            return rejectWithValue(err);
        }
    }
)

export const fetchCategoriesListData = createAsyncThunk(
    'fetchcategorieslistdata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchCategoriesListAPI();
            return res;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const fetchYearsInSchoolData = createAsyncThunk(
    'fetchyearsinschooldata',
    async (_, { rejectWithValue }) => {
        try {
            const res = await fetchYearsInSchoolDataAPI();
            return res;
        }
        catch (err) {
            return rejectWithValue(err);
        }
    }
)

const staticAPIDataSlice = createSlice({
    name: 'staticAPIData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGenderData.fulfilled, (state, action) => {
            state.genderData = action.payload
        });

        builder.addCase(fetchEthnicityData.fulfilled, (state, action) => {
            state.ethnicitiesData = action.payload
        });
        builder.addCase(fetchStatesData.fulfilled, (state, action) => {
            state.statesData = action.payload
        });
        builder.addCase(fetchSchoolListData.fulfilled, (state, action) => {
            state.schoolsList = action.payload
        });
        builder.addCase(fetchCollegeListData.fulfilled, (state, action) => {
            state.collegesList = action.payload
        });
        builder.addCase(fetchYearsInSchoolData.fulfilled, (state, action) => {
            state.yearsInSchoolIdData = action.payload
        });
        builder.addCase(fetchCategoriesListData.fulfilled, (state, action) => {
            state.categoriesList = action.payload
        })
    }
})

export default staticAPIDataSlice.reducer;